"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventApi = exports.EventApiFactory = exports.EventApiFp = exports.EventApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * EventApi - axios parameter creator
 * @export
 */
exports.EventApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary All user building events
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'created' | 'joined'} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserBuildingEvents: (filter, type, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/event/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create admin event
         * @param {EventAdminCreateInput} eventAdminCreateInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminEvent: (eventAdminCreateInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'eventAdminCreateInput' is not null or undefined
            common_1.assertParamExists('createAdminEvent', 'eventAdminCreateInput', eventAdminCreateInput);
            const localVarPath = `/admin/event/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(eventAdminCreateInput, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create user event
         * @param {string} roomId
         * @param {string} name
         * @param {string} date
         * @param {string} startTime
         * @param {string} endTime
         * @param {string} [description]
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserEvent: (roomId, name, date, startTime, endTime, description, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'roomId' is not null or undefined
            common_1.assertParamExists('createUserEvent', 'roomId', roomId);
            // verify required parameter 'name' is not null or undefined
            common_1.assertParamExists('createUserEvent', 'name', name);
            // verify required parameter 'date' is not null or undefined
            common_1.assertParamExists('createUserEvent', 'date', date);
            // verify required parameter 'startTime' is not null or undefined
            common_1.assertParamExists('createUserEvent', 'startTime', startTime);
            // verify required parameter 'endTime' is not null or undefined
            common_1.assertParamExists('createUserEvent', 'endTime', endTime);
            const localVarPath = `/event/room/{roomId}/`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (name !== undefined) {
                localVarFormParams.append('name', name);
            }
            if (description !== undefined) {
                localVarFormParams.append('description', description);
            }
            if (date !== undefined) {
                localVarFormParams.append('date', date);
            }
            if (startTime !== undefined) {
                localVarFormParams.append('startTime', startTime);
            }
            if (endTime !== undefined) {
                localVarFormParams.append('endTime', endTime);
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete admin event
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminEvent: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deleteAdminEvent', 'id', id);
            const localVarPath = `/admin/event/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete user event
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserEvent: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deleteUserEvent', 'id', id);
            const localVarPath = `/event/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit admin event
         * @param {string} id
         * @param {EventAdminCreateInput} eventAdminCreateInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdminEvent: (id, eventAdminCreateInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editAdminEvent', 'id', id);
            // verify required parameter 'eventAdminCreateInput' is not null or undefined
            common_1.assertParamExists('editAdminEvent', 'eventAdminCreateInput', eventAdminCreateInput);
            const localVarPath = `/admin/event/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(eventAdminCreateInput, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit user event
         * @param {string} id
         * @param {string} name
         * @param {string} date
         * @param {string} startTime
         * @param {string} endTime
         * @param {string} [description]
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserEvent: (id, name, date, startTime, endTime, description, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editUserEvent', 'id', id);
            // verify required parameter 'name' is not null or undefined
            common_1.assertParamExists('editUserEvent', 'name', name);
            // verify required parameter 'date' is not null or undefined
            common_1.assertParamExists('editUserEvent', 'date', date);
            // verify required parameter 'startTime' is not null or undefined
            common_1.assertParamExists('editUserEvent', 'startTime', startTime);
            // verify required parameter 'endTime' is not null or undefined
            common_1.assertParamExists('editUserEvent', 'endTime', endTime);
            const localVarPath = `/event/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (name !== undefined) {
                localVarFormParams.append('name', name);
            }
            if (description !== undefined) {
                localVarFormParams.append('description', description);
            }
            if (date !== undefined) {
                localVarFormParams.append('date', date);
            }
            if (startTime !== undefined) {
                localVarFormParams.append('startTime', startTime);
            }
            if (endTime !== undefined) {
                localVarFormParams.append('endTime', endTime);
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin event by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminEventById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getAdminEventById', 'id', id);
            const localVarPath = `/admin/event/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all events by building id
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEventsByBuildingId: (id, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getAllEventsByBuildingId', 'id', id);
            const localVarPath = `/admin/event/building/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get room events
         * @param {string} day
         * @param {string} roomId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomEvents: (day, roomId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'day' is not null or undefined
            common_1.assertParamExists('getRoomEvents', 'day', day);
            // verify required parameter 'roomId' is not null or undefined
            common_1.assertParamExists('getRoomEvents', 'roomId', roomId);
            const localVarPath = `/event/room/{roomId}/`
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (day !== undefined) {
                localVarQueryParameter['day'] = (day instanceof Date) ?
                    day.toISOString() :
                    day;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get user event by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEventById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getUserEventById', 'id', id);
            const localVarPath = `/event/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Participate to event
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participateToEvent: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('participateToEvent', 'id', id);
            const localVarPath = `/event/{id}/participate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Remove participation to event
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeParticipationToEvent: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('removeParticipationToEvent', 'id', id);
            const localVarPath = `/event/{id}/participate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Report event
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportEvent: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('reportEvent', 'id', id);
            const localVarPath = `/event/{id}/report`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Review reported event
         * @param {string} id
         * @param {InputReviewReportedEvent} [inputReviewReportedEvent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewReportedEvent: (id, inputReviewReportedEvent, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('reviewReportedEvent', 'id', id);
            const localVarPath = `/admin/event/{id}/report/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputReviewReportedEvent, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update event image
         * @param {string} id
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventImage: (id, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('updateEventImage', 'id', id);
            const localVarPath = `/admin/event/{id}/image/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * EventApi - functional programming interface
 * @export
 */
exports.EventApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.EventApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary All user building events
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'created' | 'joined'} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserBuildingEvents(filter, type, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.allUserBuildingEvents(filter, type, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Create admin event
         * @param {EventAdminCreateInput} eventAdminCreateInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminEvent(eventAdminCreateInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createAdminEvent(eventAdminCreateInput, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Create user event
         * @param {string} roomId
         * @param {string} name
         * @param {string} date
         * @param {string} startTime
         * @param {string} endTime
         * @param {string} [description]
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserEvent(roomId, name, date, startTime, endTime, description, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createUserEvent(roomId, name, date, startTime, endTime, description, file, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete admin event
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminEvent(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteAdminEvent(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete user event
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserEvent(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteUserEvent(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit admin event
         * @param {string} id
         * @param {EventAdminCreateInput} eventAdminCreateInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdminEvent(id, eventAdminCreateInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editAdminEvent(id, eventAdminCreateInput, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit user event
         * @param {string} id
         * @param {string} name
         * @param {string} date
         * @param {string} startTime
         * @param {string} endTime
         * @param {string} [description]
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserEvent(id, name, date, startTime, endTime, description, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editUserEvent(id, name, date, startTime, endTime, description, file, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin event by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminEventById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminEventById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all events by building id
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEventsByBuildingId(id, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllEventsByBuildingId(id, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get room events
         * @param {string} day
         * @param {string} roomId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomEvents(day, roomId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRoomEvents(day, roomId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get user event by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEventById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUserEventById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Participate to event
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participateToEvent(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.participateToEvent(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Remove participation to event
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeParticipationToEvent(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeParticipationToEvent(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Report event
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportEvent(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.reportEvent(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Review reported event
         * @param {string} id
         * @param {InputReviewReportedEvent} [inputReviewReportedEvent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewReportedEvent(id, inputReviewReportedEvent, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.reviewReportedEvent(id, inputReviewReportedEvent, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Update event image
         * @param {string} id
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventImage(id, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateEventImage(id, file, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * EventApi - factory interface
 * @export
 */
exports.EventApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.EventApiFp(configuration);
    return {
        /**
         *
         * @summary All user building events
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {'created' | 'joined'} [type]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserBuildingEvents(filter, type, options) {
            return localVarFp.allUserBuildingEvents(filter, type, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create admin event
         * @param {EventAdminCreateInput} eventAdminCreateInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminEvent(eventAdminCreateInput, options) {
            return localVarFp.createAdminEvent(eventAdminCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create user event
         * @param {string} roomId
         * @param {string} name
         * @param {string} date
         * @param {string} startTime
         * @param {string} endTime
         * @param {string} [description]
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserEvent(roomId, name, date, startTime, endTime, description, file, options) {
            return localVarFp.createUserEvent(roomId, name, date, startTime, endTime, description, file, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete admin event
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminEvent(id, options) {
            return localVarFp.deleteAdminEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete user event
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserEvent(id, options) {
            return localVarFp.deleteUserEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit admin event
         * @param {string} id
         * @param {EventAdminCreateInput} eventAdminCreateInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdminEvent(id, eventAdminCreateInput, options) {
            return localVarFp.editAdminEvent(id, eventAdminCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit user event
         * @param {string} id
         * @param {string} name
         * @param {string} date
         * @param {string} startTime
         * @param {string} endTime
         * @param {string} [description]
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserEvent(id, name, date, startTime, endTime, description, file, options) {
            return localVarFp.editUserEvent(id, name, date, startTime, endTime, description, file, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin event by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminEventById(id, options) {
            return localVarFp.getAdminEventById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all events by building id
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEventsByBuildingId(id, filter, options) {
            return localVarFp.getAllEventsByBuildingId(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get room events
         * @param {string} day
         * @param {string} roomId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomEvents(day, roomId, options) {
            return localVarFp.getRoomEvents(day, roomId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get user event by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEventById(id, options) {
            return localVarFp.getUserEventById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Participate to event
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participateToEvent(id, options) {
            return localVarFp.participateToEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Remove participation to event
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeParticipationToEvent(id, options) {
            return localVarFp.removeParticipationToEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Report event
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportEvent(id, options) {
            return localVarFp.reportEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Review reported event
         * @param {string} id
         * @param {InputReviewReportedEvent} [inputReviewReportedEvent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewReportedEvent(id, inputReviewReportedEvent, options) {
            return localVarFp.reviewReportedEvent(id, inputReviewReportedEvent, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update event image
         * @param {string} id
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventImage(id, file, options) {
            return localVarFp.updateEventImage(id, file, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
class EventApi extends base_1.BaseAPI {
    /**
     *
     * @summary All user building events
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {'created' | 'joined'} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    allUserBuildingEvents(filter, type, options) {
        return exports.EventApiFp(this.configuration).allUserBuildingEvents(filter, type, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create admin event
     * @param {EventAdminCreateInput} eventAdminCreateInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    createAdminEvent(eventAdminCreateInput, options) {
        return exports.EventApiFp(this.configuration).createAdminEvent(eventAdminCreateInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create user event
     * @param {string} roomId
     * @param {string} name
     * @param {string} date
     * @param {string} startTime
     * @param {string} endTime
     * @param {string} [description]
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    createUserEvent(roomId, name, date, startTime, endTime, description, file, options) {
        return exports.EventApiFp(this.configuration).createUserEvent(roomId, name, date, startTime, endTime, description, file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete admin event
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    deleteAdminEvent(id, options) {
        return exports.EventApiFp(this.configuration).deleteAdminEvent(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete user event
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    deleteUserEvent(id, options) {
        return exports.EventApiFp(this.configuration).deleteUserEvent(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit admin event
     * @param {string} id
     * @param {EventAdminCreateInput} eventAdminCreateInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    editAdminEvent(id, eventAdminCreateInput, options) {
        return exports.EventApiFp(this.configuration).editAdminEvent(id, eventAdminCreateInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit user event
     * @param {string} id
     * @param {string} name
     * @param {string} date
     * @param {string} startTime
     * @param {string} endTime
     * @param {string} [description]
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    editUserEvent(id, name, date, startTime, endTime, description, file, options) {
        return exports.EventApiFp(this.configuration).editUserEvent(id, name, date, startTime, endTime, description, file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin event by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    getAdminEventById(id, options) {
        return exports.EventApiFp(this.configuration).getAdminEventById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all events by building id
     * @param {string} id
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    getAllEventsByBuildingId(id, filter, options) {
        return exports.EventApiFp(this.configuration).getAllEventsByBuildingId(id, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get room events
     * @param {string} day
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    getRoomEvents(day, roomId, options) {
        return exports.EventApiFp(this.configuration).getRoomEvents(day, roomId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get user event by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    getUserEventById(id, options) {
        return exports.EventApiFp(this.configuration).getUserEventById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Participate to event
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    participateToEvent(id, options) {
        return exports.EventApiFp(this.configuration).participateToEvent(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Remove participation to event
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    removeParticipationToEvent(id, options) {
        return exports.EventApiFp(this.configuration).removeParticipationToEvent(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Report event
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    reportEvent(id, options) {
        return exports.EventApiFp(this.configuration).reportEvent(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Review reported event
     * @param {string} id
     * @param {InputReviewReportedEvent} [inputReviewReportedEvent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    reviewReportedEvent(id, inputReviewReportedEvent, options) {
        return exports.EventApiFp(this.configuration).reviewReportedEvent(id, inputReviewReportedEvent, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update event image
     * @param {string} id
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    updateEventImage(id, file, options) {
        return exports.EventApiFp(this.configuration).updateEventImage(id, file, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.EventApi = EventApi;
