"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuildingApi = exports.BuildingApiFactory = exports.BuildingApiFp = exports.BuildingApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * BuildingApi - axios parameter creator
 * @export
 */
exports.BuildingApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * get all admin buildings
         * @summary All admin buildings
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAdminBuildings: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/building/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create admin building
         * @param {InputCreateEditAdminBuilding} [inputCreateEditAdminBuilding] Create a building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminBuilding: (inputCreateEditAdminBuilding, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/building/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputCreateEditAdminBuilding, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete building
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuilding: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deleteBuilding', 'id', id);
            const localVarPath = `/admin/building/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit building
         * @param {string} id
         * @param {InputCreateEditAdminBuilding} [inputCreateEditAdminBuilding]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBuilding: (id, inputCreateEditAdminBuilding, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editBuilding', 'id', id);
            const localVarPath = `/admin/building/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputCreateEditAdminBuilding, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin building guests
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminBuildingGuests: (id, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getAdminBuildingGuests', 'id', id);
            const localVarPath = `/admin/building/{id}/guests/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all common rooms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCommonRooms: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/building/allCommonRooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get building by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuildingById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getBuildingById', 'id', id);
            const localVarPath = `/admin/building/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get building gate status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuildingGateStatus: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/building/gate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get forecast
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForecast: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/building/forecast`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Open building gate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openBuildingGate: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/building/gate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Send communication to building
         * @param {string} id
         * @param {CreateNotificationModel} [createNotificationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCommunicationToBuilding: (id, createNotificationModel, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('sendCommunicationToBuilding', 'id', id);
            const localVarPath = `/admin/building/{id}/communication/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(createNotificationModel, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BuildingApi - functional programming interface
 * @export
 */
exports.BuildingApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.BuildingApiAxiosParamCreator(configuration);
    return {
        /**
         * get all admin buildings
         * @summary All admin buildings
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAdminBuildings(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.allAdminBuildings(filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Create admin building
         * @param {InputCreateEditAdminBuilding} [inputCreateEditAdminBuilding] Create a building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminBuilding(inputCreateEditAdminBuilding, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createAdminBuilding(inputCreateEditAdminBuilding, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete building
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuilding(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteBuilding(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit building
         * @param {string} id
         * @param {InputCreateEditAdminBuilding} [inputCreateEditAdminBuilding]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBuilding(id, inputCreateEditAdminBuilding, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editBuilding(id, inputCreateEditAdminBuilding, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin building guests
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminBuildingGuests(id, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminBuildingGuests(id, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all common rooms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCommonRooms(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllCommonRooms(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get building by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuildingById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getBuildingById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get building gate status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuildingGateStatus(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getBuildingGateStatus(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get forecast
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForecast(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getForecast(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Open building gate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openBuildingGate(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.openBuildingGate(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Send communication to building
         * @param {string} id
         * @param {CreateNotificationModel} [createNotificationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCommunicationToBuilding(id, createNotificationModel, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.sendCommunicationToBuilding(id, createNotificationModel, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * BuildingApi - factory interface
 * @export
 */
exports.BuildingApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.BuildingApiFp(configuration);
    return {
        /**
         * get all admin buildings
         * @summary All admin buildings
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAdminBuildings(filter, options) {
            return localVarFp.allAdminBuildings(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create admin building
         * @param {InputCreateEditAdminBuilding} [inputCreateEditAdminBuilding] Create a building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminBuilding(inputCreateEditAdminBuilding, options) {
            return localVarFp.createAdminBuilding(inputCreateEditAdminBuilding, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete building
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuilding(id, options) {
            return localVarFp.deleteBuilding(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit building
         * @param {string} id
         * @param {InputCreateEditAdminBuilding} [inputCreateEditAdminBuilding]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBuilding(id, inputCreateEditAdminBuilding, options) {
            return localVarFp.editBuilding(id, inputCreateEditAdminBuilding, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin building guests
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminBuildingGuests(id, filter, options) {
            return localVarFp.getAdminBuildingGuests(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all common rooms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCommonRooms(options) {
            return localVarFp.getAllCommonRooms(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get building by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuildingById(id, options) {
            return localVarFp.getBuildingById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get building gate status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuildingGateStatus(options) {
            return localVarFp.getBuildingGateStatus(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get forecast
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForecast(options) {
            return localVarFp.getForecast(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Open building gate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openBuildingGate(options) {
            return localVarFp.openBuildingGate(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Send communication to building
         * @param {string} id
         * @param {CreateNotificationModel} [createNotificationModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCommunicationToBuilding(id, createNotificationModel, options) {
            return localVarFp.sendCommunicationToBuilding(id, createNotificationModel, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BuildingApi - object-oriented interface
 * @export
 * @class BuildingApi
 * @extends {BaseAPI}
 */
class BuildingApi extends base_1.BaseAPI {
    /**
     * get all admin buildings
     * @summary All admin buildings
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    allAdminBuildings(filter, options) {
        return exports.BuildingApiFp(this.configuration).allAdminBuildings(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create admin building
     * @param {InputCreateEditAdminBuilding} [inputCreateEditAdminBuilding] Create a building
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    createAdminBuilding(inputCreateEditAdminBuilding, options) {
        return exports.BuildingApiFp(this.configuration).createAdminBuilding(inputCreateEditAdminBuilding, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete building
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    deleteBuilding(id, options) {
        return exports.BuildingApiFp(this.configuration).deleteBuilding(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit building
     * @param {string} id
     * @param {InputCreateEditAdminBuilding} [inputCreateEditAdminBuilding]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    editBuilding(id, inputCreateEditAdminBuilding, options) {
        return exports.BuildingApiFp(this.configuration).editBuilding(id, inputCreateEditAdminBuilding, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin building guests
     * @param {string} id
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    getAdminBuildingGuests(id, filter, options) {
        return exports.BuildingApiFp(this.configuration).getAdminBuildingGuests(id, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all common rooms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    getAllCommonRooms(options) {
        return exports.BuildingApiFp(this.configuration).getAllCommonRooms(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get building by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    getBuildingById(id, options) {
        return exports.BuildingApiFp(this.configuration).getBuildingById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get building gate status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    getBuildingGateStatus(options) {
        return exports.BuildingApiFp(this.configuration).getBuildingGateStatus(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get forecast
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    getForecast(options) {
        return exports.BuildingApiFp(this.configuration).getForecast(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Open building gate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    openBuildingGate(options) {
        return exports.BuildingApiFp(this.configuration).openBuildingGate(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Send communication to building
     * @param {string} id
     * @param {CreateNotificationModel} [createNotificationModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    sendCommunicationToBuilding(id, createNotificationModel, options) {
        return exports.BuildingApiFp(this.configuration).sendCommunicationToBuilding(id, createNotificationModel, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.BuildingApi = BuildingApi;
