"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceApi = exports.PlaceApiFactory = exports.PlaceApiFp = exports.PlaceApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * PlaceApi - axios parameter creator
 * @export
 */
exports.PlaceApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Add place
         * @param {InputAddPlace} inputAddPlace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlace: (inputAddPlace, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'inputAddPlace' is not null or undefined
            common_1.assertParamExists('addPlace', 'inputAddPlace', inputAddPlace);
            const localVarPath = `/admin/place/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputAddPlace, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary All building places
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allBuildingPlaces: (id, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('allBuildingPlaces', 'id', id);
            const localVarPath = `/admin/place/building/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary All user building places
         * @param {number} [perPage]
         * @param {number} [page]
         * @param {number} [itemsPerPage]
         * @param {number} [itemsPage]
         * @param {number} [rating]
         * @param {number} [distance]
         * @param {string} [category]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserBuildingPlaces: (perPage, page, itemsPerPage, itemsPage, rating, distance, category, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/place/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }
            if (itemsPage !== undefined) {
                localVarQueryParameter['itemsPage'] = itemsPage;
            }
            if (rating !== undefined) {
                localVarQueryParameter['rating'] = rating;
            }
            if (distance !== undefined) {
                localVarQueryParameter['distance'] = distance;
            }
            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create place
         * @param {InputCreatePlace} inputCreatePlace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlace: (inputCreatePlace, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'inputCreatePlace' is not null or undefined
            common_1.assertParamExists('createPlace', 'inputCreatePlace', inputCreatePlace);
            const localVarPath = `/place/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputCreatePlace, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create review
         * @param {string} placeId
         * @param {ReviewEditCreateInput} reviewEditCreateInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReview: (placeId, reviewEditCreateInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'placeId' is not null or undefined
            common_1.assertParamExists('createReview', 'placeId', placeId);
            // verify required parameter 'reviewEditCreateInput' is not null or undefined
            common_1.assertParamExists('createReview', 'reviewEditCreateInput', reviewEditCreateInput);
            const localVarPath = `/place/{placeId}/review/`
                .replace(`{${"placeId"}}`, encodeURIComponent(String(placeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(reviewEditCreateInput, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete admin review
         * @param {string} reviewId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminReview: (reviewId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'reviewId' is not null or undefined
            common_1.assertParamExists('deleteAdminReview', 'reviewId', reviewId);
            const localVarPath = `/admin/place/reviews/{reviewId}`
                .replace(`{${"reviewId"}}`, encodeURIComponent(String(reviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete place
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlace: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deletePlace', 'id', id);
            const localVarPath = `/admin/place/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete review
         * @param {string} reviewId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReview: (reviewId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'reviewId' is not null or undefined
            common_1.assertParamExists('deleteReview', 'reviewId', reviewId);
            const localVarPath = `/place/review/{reviewId}`
                .replace(`{${"reviewId"}}`, encodeURIComponent(String(reviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit place
         * @param {string} id
         * @param {InputEditPlace} inputEditPlace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPlace: (id, inputEditPlace, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editPlace', 'id', id);
            // verify required parameter 'inputEditPlace' is not null or undefined
            common_1.assertParamExists('editPlace', 'inputEditPlace', inputEditPlace);
            const localVarPath = `/admin/place/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputEditPlace, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit review
         * @param {string} reviewId
         * @param {ReviewEditCreateInput} reviewEditCreateInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editReview: (reviewId, reviewEditCreateInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'reviewId' is not null or undefined
            common_1.assertParamExists('editReview', 'reviewId', reviewId);
            // verify required parameter 'reviewEditCreateInput' is not null or undefined
            common_1.assertParamExists('editReview', 'reviewEditCreateInput', reviewEditCreateInput);
            const localVarPath = `/place/review/{reviewId}`
                .replace(`{${"reviewId"}}`, encodeURIComponent(String(reviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(reviewEditCreateInput, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin place by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminPlaceById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getAdminPlaceById', 'id', id);
            const localVarPath = `/admin/place/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin review by id
         * @param {string} reviewId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminReviewById: (reviewId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'reviewId' is not null or undefined
            common_1.assertParamExists('getAdminReviewById', 'reviewId', reviewId);
            const localVarPath = `/admin/place/reviews/{reviewId}`
                .replace(`{${"reviewId"}}`, encodeURIComponent(String(reviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all building reviews
         * @param {string} buildingId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBuildingReviews: (buildingId, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'buildingId' is not null or undefined
            common_1.assertParamExists('getAllBuildingReviews', 'buildingId', buildingId);
            const localVarPath = `/admin/place/reviews/building/{buildingId}`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get place reviews
         * @param {string} placeId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaceReviews: (placeId, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'placeId' is not null or undefined
            common_1.assertParamExists('getPlaceReviews', 'placeId', placeId);
            const localVarPath = `/place/{placeId}/review/`
                .replace(`{${"placeId"}}`, encodeURIComponent(String(placeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get user place by place id
         * @param {string} placeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaceByPlaceId: (placeId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'placeId' is not null or undefined
            common_1.assertParamExists('getUserPlaceByPlaceId', 'placeId', placeId);
            const localVarPath = `/place/{placeId}/`
                .replace(`{${"placeId"}}`, encodeURIComponent(String(placeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get user review by id
         * @param {string} reviewId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserReviewById: (reviewId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'reviewId' is not null or undefined
            common_1.assertParamExists('getUserReviewById', 'reviewId', reviewId);
            const localVarPath = `/place/review/{reviewId}`
                .replace(`{${"reviewId"}}`, encodeURIComponent(String(reviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * PlaceApi - functional programming interface
 * @export
 */
exports.PlaceApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.PlaceApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Add place
         * @param {InputAddPlace} inputAddPlace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlace(inputAddPlace, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addPlace(inputAddPlace, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary All building places
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allBuildingPlaces(id, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.allBuildingPlaces(id, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary All user building places
         * @param {number} [perPage]
         * @param {number} [page]
         * @param {number} [itemsPerPage]
         * @param {number} [itemsPage]
         * @param {number} [rating]
         * @param {number} [distance]
         * @param {string} [category]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserBuildingPlaces(perPage, page, itemsPerPage, itemsPage, rating, distance, category, sort, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.allUserBuildingPlaces(perPage, page, itemsPerPage, itemsPage, rating, distance, category, sort, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Create place
         * @param {InputCreatePlace} inputCreatePlace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlace(inputCreatePlace, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createPlace(inputCreatePlace, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Create review
         * @param {string} placeId
         * @param {ReviewEditCreateInput} reviewEditCreateInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReview(placeId, reviewEditCreateInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createReview(placeId, reviewEditCreateInput, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete admin review
         * @param {string} reviewId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminReview(reviewId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteAdminReview(reviewId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete place
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlace(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deletePlace(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete review
         * @param {string} reviewId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReview(reviewId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteReview(reviewId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit place
         * @param {string} id
         * @param {InputEditPlace} inputEditPlace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPlace(id, inputEditPlace, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editPlace(id, inputEditPlace, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit review
         * @param {string} reviewId
         * @param {ReviewEditCreateInput} reviewEditCreateInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editReview(reviewId, reviewEditCreateInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editReview(reviewId, reviewEditCreateInput, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin place by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminPlaceById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminPlaceById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin review by id
         * @param {string} reviewId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminReviewById(reviewId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminReviewById(reviewId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all building reviews
         * @param {string} buildingId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBuildingReviews(buildingId, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllBuildingReviews(buildingId, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get place reviews
         * @param {string} placeId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaceReviews(placeId, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPlaceReviews(placeId, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get user place by place id
         * @param {string} placeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaceByPlaceId(placeId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUserPlaceByPlaceId(placeId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get user review by id
         * @param {string} reviewId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserReviewById(reviewId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUserReviewById(reviewId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * PlaceApi - factory interface
 * @export
 */
exports.PlaceApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.PlaceApiFp(configuration);
    return {
        /**
         *
         * @summary Add place
         * @param {InputAddPlace} inputAddPlace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlace(inputAddPlace, options) {
            return localVarFp.addPlace(inputAddPlace, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary All building places
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allBuildingPlaces(id, filter, options) {
            return localVarFp.allBuildingPlaces(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary All user building places
         * @param {number} [perPage]
         * @param {number} [page]
         * @param {number} [itemsPerPage]
         * @param {number} [itemsPage]
         * @param {number} [rating]
         * @param {number} [distance]
         * @param {string} [category]
         * @param {string} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUserBuildingPlaces(perPage, page, itemsPerPage, itemsPage, rating, distance, category, sort, options) {
            return localVarFp.allUserBuildingPlaces(perPage, page, itemsPerPage, itemsPage, rating, distance, category, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create place
         * @param {InputCreatePlace} inputCreatePlace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlace(inputCreatePlace, options) {
            return localVarFp.createPlace(inputCreatePlace, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create review
         * @param {string} placeId
         * @param {ReviewEditCreateInput} reviewEditCreateInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReview(placeId, reviewEditCreateInput, options) {
            return localVarFp.createReview(placeId, reviewEditCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete admin review
         * @param {string} reviewId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminReview(reviewId, options) {
            return localVarFp.deleteAdminReview(reviewId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete place
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlace(id, options) {
            return localVarFp.deletePlace(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete review
         * @param {string} reviewId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReview(reviewId, options) {
            return localVarFp.deleteReview(reviewId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit place
         * @param {string} id
         * @param {InputEditPlace} inputEditPlace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPlace(id, inputEditPlace, options) {
            return localVarFp.editPlace(id, inputEditPlace, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit review
         * @param {string} reviewId
         * @param {ReviewEditCreateInput} reviewEditCreateInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editReview(reviewId, reviewEditCreateInput, options) {
            return localVarFp.editReview(reviewId, reviewEditCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin place by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminPlaceById(id, options) {
            return localVarFp.getAdminPlaceById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin review by id
         * @param {string} reviewId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminReviewById(reviewId, options) {
            return localVarFp.getAdminReviewById(reviewId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all building reviews
         * @param {string} buildingId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBuildingReviews(buildingId, filter, options) {
            return localVarFp.getAllBuildingReviews(buildingId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get place reviews
         * @param {string} placeId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaceReviews(placeId, filter, options) {
            return localVarFp.getPlaceReviews(placeId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get user place by place id
         * @param {string} placeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPlaceByPlaceId(placeId, options) {
            return localVarFp.getUserPlaceByPlaceId(placeId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get user review by id
         * @param {string} reviewId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserReviewById(reviewId, options) {
            return localVarFp.getUserReviewById(reviewId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * PlaceApi - object-oriented interface
 * @export
 * @class PlaceApi
 * @extends {BaseAPI}
 */
class PlaceApi extends base_1.BaseAPI {
    /**
     *
     * @summary Add place
     * @param {InputAddPlace} inputAddPlace
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    addPlace(inputAddPlace, options) {
        return exports.PlaceApiFp(this.configuration).addPlace(inputAddPlace, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary All building places
     * @param {string} id
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    allBuildingPlaces(id, filter, options) {
        return exports.PlaceApiFp(this.configuration).allBuildingPlaces(id, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary All user building places
     * @param {number} [perPage]
     * @param {number} [page]
     * @param {number} [itemsPerPage]
     * @param {number} [itemsPage]
     * @param {number} [rating]
     * @param {number} [distance]
     * @param {string} [category]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    allUserBuildingPlaces(perPage, page, itemsPerPage, itemsPage, rating, distance, category, sort, options) {
        return exports.PlaceApiFp(this.configuration).allUserBuildingPlaces(perPage, page, itemsPerPage, itemsPage, rating, distance, category, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create place
     * @param {InputCreatePlace} inputCreatePlace
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    createPlace(inputCreatePlace, options) {
        return exports.PlaceApiFp(this.configuration).createPlace(inputCreatePlace, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create review
     * @param {string} placeId
     * @param {ReviewEditCreateInput} reviewEditCreateInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    createReview(placeId, reviewEditCreateInput, options) {
        return exports.PlaceApiFp(this.configuration).createReview(placeId, reviewEditCreateInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete admin review
     * @param {string} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    deleteAdminReview(reviewId, options) {
        return exports.PlaceApiFp(this.configuration).deleteAdminReview(reviewId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete place
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    deletePlace(id, options) {
        return exports.PlaceApiFp(this.configuration).deletePlace(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete review
     * @param {string} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    deleteReview(reviewId, options) {
        return exports.PlaceApiFp(this.configuration).deleteReview(reviewId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit place
     * @param {string} id
     * @param {InputEditPlace} inputEditPlace
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    editPlace(id, inputEditPlace, options) {
        return exports.PlaceApiFp(this.configuration).editPlace(id, inputEditPlace, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit review
     * @param {string} reviewId
     * @param {ReviewEditCreateInput} reviewEditCreateInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    editReview(reviewId, reviewEditCreateInput, options) {
        return exports.PlaceApiFp(this.configuration).editReview(reviewId, reviewEditCreateInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin place by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    getAdminPlaceById(id, options) {
        return exports.PlaceApiFp(this.configuration).getAdminPlaceById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin review by id
     * @param {string} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    getAdminReviewById(reviewId, options) {
        return exports.PlaceApiFp(this.configuration).getAdminReviewById(reviewId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all building reviews
     * @param {string} buildingId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    getAllBuildingReviews(buildingId, filter, options) {
        return exports.PlaceApiFp(this.configuration).getAllBuildingReviews(buildingId, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get place reviews
     * @param {string} placeId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    getPlaceReviews(placeId, filter, options) {
        return exports.PlaceApiFp(this.configuration).getPlaceReviews(placeId, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get user place by place id
     * @param {string} placeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    getUserPlaceByPlaceId(placeId, options) {
        return exports.PlaceApiFp(this.configuration).getUserPlaceByPlaceId(placeId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get user review by id
     * @param {string} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaceApi
     */
    getUserReviewById(reviewId, options) {
        return exports.PlaceApiFp(this.configuration).getUserReviewById(reviewId, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.PlaceApi = PlaceApi;
