"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PresetApi = exports.PresetApiFactory = exports.PresetApiFp = exports.PresetApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * PresetApi - axios parameter creator
 * @export
 */
exports.PresetApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Add preset
         * @param {string} id
         * @param {PresetCreateEditInput} presetCreateEditInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPreset: (id, presetCreateEditInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('addPreset', 'id', id);
            // verify required parameter 'presetCreateEditInput' is not null or undefined
            common_1.assertParamExists('addPreset', 'presetCreateEditInput', presetCreateEditInput);
            const localVarPath = `/admin/preset/room/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(presetCreateEditInput, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary All room presets
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allRoomPresets: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('allRoomPresets', 'id', id);
            const localVarPath = `/admin/preset/room/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete preset
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePreset: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deletePreset', 'id', id);
            const localVarPath = `/admin/preset/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit or create preset
         * @param {string} id
         * @param {string} presetId
         * @param {EditOrCreatePreset} [editOrCreatePreset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOrCreatePreset: (id, presetId, editOrCreatePreset, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editOrCreatePreset', 'id', id);
            // verify required parameter 'presetId' is not null or undefined
            common_1.assertParamExists('editOrCreatePreset', 'presetId', presetId);
            const localVarPath = `/admin/room/{id}/preset/{presetId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"presetId"}}`, encodeURIComponent(String(presetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editOrCreatePreset, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit preset
         * @param {string} id
         * @param {PresetCreateEditInput} presetCreateEditInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPreset: (id, presetCreateEditInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editPreset', 'id', id);
            // verify required parameter 'presetCreateEditInput' is not null or undefined
            common_1.assertParamExists('editPreset', 'presetCreateEditInput', presetCreateEditInput);
            const localVarPath = `/admin/preset/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(presetCreateEditInput, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get preset by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getPresetById', 'id', id);
            const localVarPath = `/admin/preset/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get room presets
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomPresets: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getRoomPresets', 'id', id);
            const localVarPath = `/admin/room/{id}/preset/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * PresetApi - functional programming interface
 * @export
 */
exports.PresetApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.PresetApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Add preset
         * @param {string} id
         * @param {PresetCreateEditInput} presetCreateEditInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPreset(id, presetCreateEditInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addPreset(id, presetCreateEditInput, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary All room presets
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allRoomPresets(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.allRoomPresets(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete preset
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePreset(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deletePreset(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit or create preset
         * @param {string} id
         * @param {string} presetId
         * @param {EditOrCreatePreset} [editOrCreatePreset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOrCreatePreset(id, presetId, editOrCreatePreset, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editOrCreatePreset(id, presetId, editOrCreatePreset, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit preset
         * @param {string} id
         * @param {PresetCreateEditInput} presetCreateEditInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPreset(id, presetCreateEditInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editPreset(id, presetCreateEditInput, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get preset by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPresetById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get room presets
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomPresets(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRoomPresets(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * PresetApi - factory interface
 * @export
 */
exports.PresetApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.PresetApiFp(configuration);
    return {
        /**
         *
         * @summary Add preset
         * @param {string} id
         * @param {PresetCreateEditInput} presetCreateEditInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPreset(id, presetCreateEditInput, options) {
            return localVarFp.addPreset(id, presetCreateEditInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary All room presets
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allRoomPresets(id, options) {
            return localVarFp.allRoomPresets(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete preset
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePreset(id, options) {
            return localVarFp.deletePreset(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit or create preset
         * @param {string} id
         * @param {string} presetId
         * @param {EditOrCreatePreset} [editOrCreatePreset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOrCreatePreset(id, presetId, editOrCreatePreset, options) {
            return localVarFp.editOrCreatePreset(id, presetId, editOrCreatePreset, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit preset
         * @param {string} id
         * @param {PresetCreateEditInput} presetCreateEditInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPreset(id, presetCreateEditInput, options) {
            return localVarFp.editPreset(id, presetCreateEditInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get preset by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresetById(id, options) {
            return localVarFp.getPresetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get room presets
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomPresets(id, options) {
            return localVarFp.getRoomPresets(id, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * PresetApi - object-oriented interface
 * @export
 * @class PresetApi
 * @extends {BaseAPI}
 */
class PresetApi extends base_1.BaseAPI {
    /**
     *
     * @summary Add preset
     * @param {string} id
     * @param {PresetCreateEditInput} presetCreateEditInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetApi
     */
    addPreset(id, presetCreateEditInput, options) {
        return exports.PresetApiFp(this.configuration).addPreset(id, presetCreateEditInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary All room presets
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetApi
     */
    allRoomPresets(id, options) {
        return exports.PresetApiFp(this.configuration).allRoomPresets(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete preset
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetApi
     */
    deletePreset(id, options) {
        return exports.PresetApiFp(this.configuration).deletePreset(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit or create preset
     * @param {string} id
     * @param {string} presetId
     * @param {EditOrCreatePreset} [editOrCreatePreset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetApi
     */
    editOrCreatePreset(id, presetId, editOrCreatePreset, options) {
        return exports.PresetApiFp(this.configuration).editOrCreatePreset(id, presetId, editOrCreatePreset, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit preset
     * @param {string} id
     * @param {PresetCreateEditInput} presetCreateEditInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetApi
     */
    editPreset(id, presetCreateEditInput, options) {
        return exports.PresetApiFp(this.configuration).editPreset(id, presetCreateEditInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get preset by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetApi
     */
    getPresetById(id, options) {
        return exports.PresetApiFp(this.configuration).getPresetById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get room presets
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresetApi
     */
    getRoomPresets(id, options) {
        return exports.PresetApiFp(this.configuration).getRoomPresets(id, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.PresetApi = PresetApi;
