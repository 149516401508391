"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationApi = exports.AuthenticationApiFactory = exports.AuthenticationApiFp = exports.AuthenticationApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
exports.AuthenticationApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Execute first authentication
         * @param {InputExecuteFirstAuthentication} [inputExecuteFirstAuthentication]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeFirstAuthentication: (inputExecuteFirstAuthentication, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/authentication/firstAuthentication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputExecuteFirstAuthentication, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary User can login through email and password
         * @param {InputAuthenticationLogin} inputAuthenticationLogin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: (inputAuthenticationLogin, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'inputAuthenticationLogin' is not null or undefined
            common_1.assertParamExists('login', 'inputAuthenticationLogin', inputAuthenticationLogin);
            const localVarPath = `/authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputAuthenticationLogin, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Login admin
         * @param {InputAuthenticationLogin1} inputAuthenticationLogin1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAdmin: (inputAuthenticationLogin1, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'inputAuthenticationLogin1' is not null or undefined
            common_1.assertParamExists('loginAdmin', 'inputAuthenticationLogin1', inputAuthenticationLogin1);
            const localVarPath = `/authentication/adminLogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputAuthenticationLogin1, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Logout from belvillage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/authentication/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Register
         * @param {InputRegister} inputRegister
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: (inputRegister, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'inputRegister' is not null or undefined
            common_1.assertParamExists('register', 'inputRegister', inputRegister);
            const localVarPath = `/authentication/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputRegister, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Test upload
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testUpload: (file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/authentication/test-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readOne\", \"Account\"]
         * @summary Verify email | Permission required: [\"readOne\", \"Account\"]
         * @param {InputVerifyEmail} inputVerifyEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail: (inputVerifyEmail, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'inputVerifyEmail' is not null or undefined
            common_1.assertParamExists('verifyEmail', 'inputVerifyEmail', inputVerifyEmail);
            const localVarPath = `/authentication/verify-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputVerifyEmail, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AuthenticationApi - functional programming interface
 * @export
 */
exports.AuthenticationApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AuthenticationApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Execute first authentication
         * @param {InputExecuteFirstAuthentication} [inputExecuteFirstAuthentication]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeFirstAuthentication(inputExecuteFirstAuthentication, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.executeFirstAuthentication(inputExecuteFirstAuthentication, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary User can login through email and password
         * @param {InputAuthenticationLogin} inputAuthenticationLogin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(inputAuthenticationLogin, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.login(inputAuthenticationLogin, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Login admin
         * @param {InputAuthenticationLogin1} inputAuthenticationLogin1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAdmin(inputAuthenticationLogin1, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.loginAdmin(inputAuthenticationLogin1, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Logout from belvillage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.logout(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Register
         * @param {InputRegister} inputRegister
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(inputRegister, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.register(inputRegister, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Test upload
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testUpload(file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.testUpload(file, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readOne\", \"Account\"]
         * @summary Verify email | Permission required: [\"readOne\", \"Account\"]
         * @param {InputVerifyEmail} inputVerifyEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail(inputVerifyEmail, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.verifyEmail(inputVerifyEmail, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AuthenticationApi - factory interface
 * @export
 */
exports.AuthenticationApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AuthenticationApiFp(configuration);
    return {
        /**
         *
         * @summary Execute first authentication
         * @param {InputExecuteFirstAuthentication} [inputExecuteFirstAuthentication]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeFirstAuthentication(inputExecuteFirstAuthentication, options) {
            return localVarFp.executeFirstAuthentication(inputExecuteFirstAuthentication, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary User can login through email and password
         * @param {InputAuthenticationLogin} inputAuthenticationLogin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(inputAuthenticationLogin, options) {
            return localVarFp.login(inputAuthenticationLogin, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Login admin
         * @param {InputAuthenticationLogin1} inputAuthenticationLogin1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAdmin(inputAuthenticationLogin1, options) {
            return localVarFp.loginAdmin(inputAuthenticationLogin1, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Logout from belvillage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options) {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Register
         * @param {InputRegister} inputRegister
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(inputRegister, options) {
            return localVarFp.register(inputRegister, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Test upload
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testUpload(file, options) {
            return localVarFp.testUpload(file, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readOne\", \"Account\"]
         * @summary Verify email | Permission required: [\"readOne\", \"Account\"]
         * @param {InputVerifyEmail} inputVerifyEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail(inputVerifyEmail, options) {
            return localVarFp.verifyEmail(inputVerifyEmail, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
class AuthenticationApi extends base_1.BaseAPI {
    /**
     *
     * @summary Execute first authentication
     * @param {InputExecuteFirstAuthentication} [inputExecuteFirstAuthentication]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    executeFirstAuthentication(inputExecuteFirstAuthentication, options) {
        return exports.AuthenticationApiFp(this.configuration).executeFirstAuthentication(inputExecuteFirstAuthentication, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary User can login through email and password
     * @param {InputAuthenticationLogin} inputAuthenticationLogin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    login(inputAuthenticationLogin, options) {
        return exports.AuthenticationApiFp(this.configuration).login(inputAuthenticationLogin, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Login admin
     * @param {InputAuthenticationLogin1} inputAuthenticationLogin1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    loginAdmin(inputAuthenticationLogin1, options) {
        return exports.AuthenticationApiFp(this.configuration).loginAdmin(inputAuthenticationLogin1, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Logout from belvillage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    logout(options) {
        return exports.AuthenticationApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Register
     * @param {InputRegister} inputRegister
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    register(inputRegister, options) {
        return exports.AuthenticationApiFp(this.configuration).register(inputRegister, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Test upload
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    testUpload(file, options) {
        return exports.AuthenticationApiFp(this.configuration).testUpload(file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readOne\", \"Account\"]
     * @summary Verify email | Permission required: [\"readOne\", \"Account\"]
     * @param {InputVerifyEmail} inputVerifyEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    verifyEmail(inputVerifyEmail, options) {
        return exports.AuthenticationApiFp(this.configuration).verifyEmail(inputVerifyEmail, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AuthenticationApi = AuthenticationApi;
