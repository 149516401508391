"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoomApi = exports.RoomApiFactory = exports.RoomApiFp = exports.RoomApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * RoomApi - axios parameter creator
 * @export
 */
exports.RoomApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Add user preset
         * @param {PresetCreateEditInput} [presetCreateEditInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserPreset: (presetCreateEditInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/room/preset/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(presetCreateEditInput, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * get all rooms as admin
         * @summary All admin rooms by building id
         * @param {string} buildingId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAdminRoomsByBuildingId: (buildingId, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'buildingId' is not null or undefined
            common_1.assertParamExists('allAdminRoomsByBuildingId', 'buildingId', buildingId);
            const localVarPath = `/admin/room/building/{buildingId}/`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create admin room air conditioning
         * @param {string} id
         * @param {EditOrCreateAirConditioningModel} [editOrCreateAirConditioningModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminRoomAirConditioning: (id, editOrCreateAirConditioningModel, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('createAdminRoomAirConditioning', 'id', id);
            const localVarPath = `/admin/room/{id}/airConditioning/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editOrCreateAirConditioningModel, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create room
         * @param {string} buildingId
         * @param {string} name
         * @param {string} type
         * @param {any} [file]
         * @param {string} [doorHsycoId]
         * @param {string} [temperatureComponent]
         * @param {string} [externalId]
         * @param {RoomCustomSettings} [customSettings]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoom: (buildingId, name, type, file, doorHsycoId, temperatureComponent, externalId, customSettings, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'buildingId' is not null or undefined
            common_1.assertParamExists('createRoom', 'buildingId', buildingId);
            // verify required parameter 'name' is not null or undefined
            common_1.assertParamExists('createRoom', 'name', name);
            // verify required parameter 'type' is not null or undefined
            common_1.assertParamExists('createRoom', 'type', type);
            const localVarPath = `/admin/room/building/{buildingId}/`
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (name !== undefined) {
                localVarFormParams.append('name', name);
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            if (doorHsycoId !== undefined) {
                localVarFormParams.append('doorHsycoId', doorHsycoId);
            }
            if (type !== undefined) {
                localVarFormParams.append('type', type);
            }
            if (temperatureComponent !== undefined) {
                localVarFormParams.append('temperatureComponent', temperatureComponent);
            }
            if (externalId !== undefined) {
                localVarFormParams.append('externalId', externalId);
            }
            if (customSettings !== undefined) {
                localVarFormParams.append('customSettings', new Blob([JSON.stringify(customSettings)], { type: "application/json", }));
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete air conditioning
         * @param {string} airConditioningId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAirConditioning: (airConditioningId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'airConditioningId' is not null or undefined
            common_1.assertParamExists('deleteAirConditioning', 'airConditioningId', airConditioningId);
            const localVarPath = `/admin/airConditioning/{airConditioningId}/`
                .replace(`{${"airConditioningId"}}`, encodeURIComponent(String(airConditioningId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete room
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoom: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deleteRoom', 'id', id);
            const localVarPath = `/admin/room/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete user preset
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPreset: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deleteUserPreset', 'id', id);
            const localVarPath = `/room/preset/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit admin room air conditioning
         * @param {string} airConditioningId
         * @param {EditOrCreateAirConditioningModel} [editOrCreateAirConditioningModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdminRoomAirConditioning: (airConditioningId, editOrCreateAirConditioningModel, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'airConditioningId' is not null or undefined
            common_1.assertParamExists('editAdminRoomAirConditioning', 'airConditioningId', airConditioningId);
            const localVarPath = `/admin/airConditioning/{airConditioningId}/`
                .replace(`{${"airConditioningId"}}`, encodeURIComponent(String(airConditioningId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editOrCreateAirConditioningModel, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit room
         * @param {string} id
         * @param {string} name
         * @param {string} type
         * @param {any} [file]
         * @param {string} [doorHsycoId]
         * @param {string} [temperatureComponent]
         * @param {string} [externalId]
         * @param {RoomCustomSettings} [customSettings]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRoom: (id, name, type, file, doorHsycoId, temperatureComponent, externalId, customSettings, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editRoom', 'id', id);
            // verify required parameter 'name' is not null or undefined
            common_1.assertParamExists('editRoom', 'name', name);
            // verify required parameter 'type' is not null or undefined
            common_1.assertParamExists('editRoom', 'type', type);
            const localVarPath = `/admin/room/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (name !== undefined) {
                localVarFormParams.append('name', name);
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            if (doorHsycoId !== undefined) {
                localVarFormParams.append('doorHsycoId', doorHsycoId);
            }
            if (type !== undefined) {
                localVarFormParams.append('type', type);
            }
            if (temperatureComponent !== undefined) {
                localVarFormParams.append('temperatureComponent', temperatureComponent);
            }
            if (externalId !== undefined) {
                localVarFormParams.append('externalId', externalId);
            }
            if (customSettings !== undefined) {
                localVarFormParams.append('customSettings', new Blob([JSON.stringify(customSettings)], { type: "application/json", }));
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit user preset
         * @param {string} id
         * @param {PresetCreateEditInput} [presetCreateEditInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserPreset: (id, presetCreateEditInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editUserPreset', 'id', id);
            const localVarPath = `/room/preset/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(presetCreateEditInput, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Generate check in code
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCheckInCode: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('generateCheckInCode', 'id', id);
            const localVarPath = `/admin/room/{id}/generate/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin room air conditioning
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminRoomAirConditioning: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getAdminRoomAirConditioning', 'id', id);
            const localVarPath = `/admin/room/{id}/airConditioning/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get air conditioning schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAirConditioningSchedule: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/room/airConditioning/schedule/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get room air conditioning
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomAirConditioning: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/room/airConditioning/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get room by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getRoomById', 'id', id);
            const localVarPath = `/admin/room/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get room door status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomDoorStatus: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/room/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get temperatures
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemperatures: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/room/temperature/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get user preset by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPresetById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getUserPresetById', 'id', id);
            const localVarPath = `/room/preset/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get user room presets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRoomPresets: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/room/preset/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Open room door
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openRoomDoor: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/room/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Schedule room air conditioning
         * @param {EditOrCreateDomoticItemSchedule} [editOrCreateDomoticItemSchedule]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleRoomAirConditioning: (editOrCreateDomoticItemSchedule, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/room/airConditioning/schedule/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editOrCreateDomoticItemSchedule, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Set room air conditioning
         * @param {EditAirConditioningInput} [editAirConditioningInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRoomAirConditioning: (editAirConditioningInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/room/airConditioning/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editAirConditioningInput, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * RoomApi - functional programming interface
 * @export
 */
exports.RoomApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.RoomApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Add user preset
         * @param {PresetCreateEditInput} [presetCreateEditInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserPreset(presetCreateEditInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addUserPreset(presetCreateEditInput, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * get all rooms as admin
         * @summary All admin rooms by building id
         * @param {string} buildingId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAdminRoomsByBuildingId(buildingId, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.allAdminRoomsByBuildingId(buildingId, filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Create admin room air conditioning
         * @param {string} id
         * @param {EditOrCreateAirConditioningModel} [editOrCreateAirConditioningModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminRoomAirConditioning(id, editOrCreateAirConditioningModel, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createAdminRoomAirConditioning(id, editOrCreateAirConditioningModel, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Create room
         * @param {string} buildingId
         * @param {string} name
         * @param {string} type
         * @param {any} [file]
         * @param {string} [doorHsycoId]
         * @param {string} [temperatureComponent]
         * @param {string} [externalId]
         * @param {RoomCustomSettings} [customSettings]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoom(buildingId, name, type, file, doorHsycoId, temperatureComponent, externalId, customSettings, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createRoom(buildingId, name, type, file, doorHsycoId, temperatureComponent, externalId, customSettings, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete air conditioning
         * @param {string} airConditioningId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAirConditioning(airConditioningId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteAirConditioning(airConditioningId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete room
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoom(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteRoom(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete user preset
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPreset(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteUserPreset(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit admin room air conditioning
         * @param {string} airConditioningId
         * @param {EditOrCreateAirConditioningModel} [editOrCreateAirConditioningModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdminRoomAirConditioning(airConditioningId, editOrCreateAirConditioningModel, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editAdminRoomAirConditioning(airConditioningId, editOrCreateAirConditioningModel, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit room
         * @param {string} id
         * @param {string} name
         * @param {string} type
         * @param {any} [file]
         * @param {string} [doorHsycoId]
         * @param {string} [temperatureComponent]
         * @param {string} [externalId]
         * @param {RoomCustomSettings} [customSettings]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRoom(id, name, type, file, doorHsycoId, temperatureComponent, externalId, customSettings, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editRoom(id, name, type, file, doorHsycoId, temperatureComponent, externalId, customSettings, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit user preset
         * @param {string} id
         * @param {PresetCreateEditInput} [presetCreateEditInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserPreset(id, presetCreateEditInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editUserPreset(id, presetCreateEditInput, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Generate check in code
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCheckInCode(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.generateCheckInCode(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin room air conditioning
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminRoomAirConditioning(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminRoomAirConditioning(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get air conditioning schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAirConditioningSchedule(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAirConditioningSchedule(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get room air conditioning
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomAirConditioning(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRoomAirConditioning(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get room by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRoomById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get room door status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomDoorStatus(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRoomDoorStatus(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get temperatures
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemperatures(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTemperatures(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get user preset by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPresetById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUserPresetById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get user room presets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRoomPresets(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUserRoomPresets(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Open room door
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openRoomDoor(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.openRoomDoor(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Schedule room air conditioning
         * @param {EditOrCreateDomoticItemSchedule} [editOrCreateDomoticItemSchedule]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleRoomAirConditioning(editOrCreateDomoticItemSchedule, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.scheduleRoomAirConditioning(editOrCreateDomoticItemSchedule, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Set room air conditioning
         * @param {EditAirConditioningInput} [editAirConditioningInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRoomAirConditioning(editAirConditioningInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setRoomAirConditioning(editAirConditioningInput, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * RoomApi - factory interface
 * @export
 */
exports.RoomApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.RoomApiFp(configuration);
    return {
        /**
         *
         * @summary Add user preset
         * @param {PresetCreateEditInput} [presetCreateEditInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserPreset(presetCreateEditInput, options) {
            return localVarFp.addUserPreset(presetCreateEditInput, options).then((request) => request(axios, basePath));
        },
        /**
         * get all rooms as admin
         * @summary All admin rooms by building id
         * @param {string} buildingId
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allAdminRoomsByBuildingId(buildingId, filter, options) {
            return localVarFp.allAdminRoomsByBuildingId(buildingId, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create admin room air conditioning
         * @param {string} id
         * @param {EditOrCreateAirConditioningModel} [editOrCreateAirConditioningModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdminRoomAirConditioning(id, editOrCreateAirConditioningModel, options) {
            return localVarFp.createAdminRoomAirConditioning(id, editOrCreateAirConditioningModel, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create room
         * @param {string} buildingId
         * @param {string} name
         * @param {string} type
         * @param {any} [file]
         * @param {string} [doorHsycoId]
         * @param {string} [temperatureComponent]
         * @param {string} [externalId]
         * @param {RoomCustomSettings} [customSettings]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoom(buildingId, name, type, file, doorHsycoId, temperatureComponent, externalId, customSettings, options) {
            return localVarFp.createRoom(buildingId, name, type, file, doorHsycoId, temperatureComponent, externalId, customSettings, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete air conditioning
         * @param {string} airConditioningId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAirConditioning(airConditioningId, options) {
            return localVarFp.deleteAirConditioning(airConditioningId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete room
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoom(id, options) {
            return localVarFp.deleteRoom(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete user preset
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPreset(id, options) {
            return localVarFp.deleteUserPreset(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit admin room air conditioning
         * @param {string} airConditioningId
         * @param {EditOrCreateAirConditioningModel} [editOrCreateAirConditioningModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdminRoomAirConditioning(airConditioningId, editOrCreateAirConditioningModel, options) {
            return localVarFp.editAdminRoomAirConditioning(airConditioningId, editOrCreateAirConditioningModel, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit room
         * @param {string} id
         * @param {string} name
         * @param {string} type
         * @param {any} [file]
         * @param {string} [doorHsycoId]
         * @param {string} [temperatureComponent]
         * @param {string} [externalId]
         * @param {RoomCustomSettings} [customSettings]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRoom(id, name, type, file, doorHsycoId, temperatureComponent, externalId, customSettings, options) {
            return localVarFp.editRoom(id, name, type, file, doorHsycoId, temperatureComponent, externalId, customSettings, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit user preset
         * @param {string} id
         * @param {PresetCreateEditInput} [presetCreateEditInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserPreset(id, presetCreateEditInput, options) {
            return localVarFp.editUserPreset(id, presetCreateEditInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Generate check in code
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCheckInCode(id, options) {
            return localVarFp.generateCheckInCode(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin room air conditioning
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminRoomAirConditioning(id, options) {
            return localVarFp.getAdminRoomAirConditioning(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get air conditioning schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAirConditioningSchedule(options) {
            return localVarFp.getAirConditioningSchedule(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get room air conditioning
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomAirConditioning(options) {
            return localVarFp.getRoomAirConditioning(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get room by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomById(id, options) {
            return localVarFp.getRoomById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get room door status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoomDoorStatus(options) {
            return localVarFp.getRoomDoorStatus(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get temperatures
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemperatures(options) {
            return localVarFp.getTemperatures(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get user preset by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPresetById(id, options) {
            return localVarFp.getUserPresetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get user room presets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRoomPresets(options) {
            return localVarFp.getUserRoomPresets(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Open room door
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openRoomDoor(options) {
            return localVarFp.openRoomDoor(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Schedule room air conditioning
         * @param {EditOrCreateDomoticItemSchedule} [editOrCreateDomoticItemSchedule]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleRoomAirConditioning(editOrCreateDomoticItemSchedule, options) {
            return localVarFp.scheduleRoomAirConditioning(editOrCreateDomoticItemSchedule, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Set room air conditioning
         * @param {EditAirConditioningInput} [editAirConditioningInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRoomAirConditioning(editAirConditioningInput, options) {
            return localVarFp.setRoomAirConditioning(editAirConditioningInput, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * RoomApi - object-oriented interface
 * @export
 * @class RoomApi
 * @extends {BaseAPI}
 */
class RoomApi extends base_1.BaseAPI {
    /**
     *
     * @summary Add user preset
     * @param {PresetCreateEditInput} [presetCreateEditInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    addUserPreset(presetCreateEditInput, options) {
        return exports.RoomApiFp(this.configuration).addUserPreset(presetCreateEditInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * get all rooms as admin
     * @summary All admin rooms by building id
     * @param {string} buildingId
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    allAdminRoomsByBuildingId(buildingId, filter, options) {
        return exports.RoomApiFp(this.configuration).allAdminRoomsByBuildingId(buildingId, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create admin room air conditioning
     * @param {string} id
     * @param {EditOrCreateAirConditioningModel} [editOrCreateAirConditioningModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    createAdminRoomAirConditioning(id, editOrCreateAirConditioningModel, options) {
        return exports.RoomApiFp(this.configuration).createAdminRoomAirConditioning(id, editOrCreateAirConditioningModel, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create room
     * @param {string} buildingId
     * @param {string} name
     * @param {string} type
     * @param {any} [file]
     * @param {string} [doorHsycoId]
     * @param {string} [temperatureComponent]
     * @param {string} [externalId]
     * @param {RoomCustomSettings} [customSettings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    createRoom(buildingId, name, type, file, doorHsycoId, temperatureComponent, externalId, customSettings, options) {
        return exports.RoomApiFp(this.configuration).createRoom(buildingId, name, type, file, doorHsycoId, temperatureComponent, externalId, customSettings, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete air conditioning
     * @param {string} airConditioningId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    deleteAirConditioning(airConditioningId, options) {
        return exports.RoomApiFp(this.configuration).deleteAirConditioning(airConditioningId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete room
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    deleteRoom(id, options) {
        return exports.RoomApiFp(this.configuration).deleteRoom(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete user preset
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    deleteUserPreset(id, options) {
        return exports.RoomApiFp(this.configuration).deleteUserPreset(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit admin room air conditioning
     * @param {string} airConditioningId
     * @param {EditOrCreateAirConditioningModel} [editOrCreateAirConditioningModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    editAdminRoomAirConditioning(airConditioningId, editOrCreateAirConditioningModel, options) {
        return exports.RoomApiFp(this.configuration).editAdminRoomAirConditioning(airConditioningId, editOrCreateAirConditioningModel, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit room
     * @param {string} id
     * @param {string} name
     * @param {string} type
     * @param {any} [file]
     * @param {string} [doorHsycoId]
     * @param {string} [temperatureComponent]
     * @param {string} [externalId]
     * @param {RoomCustomSettings} [customSettings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    editRoom(id, name, type, file, doorHsycoId, temperatureComponent, externalId, customSettings, options) {
        return exports.RoomApiFp(this.configuration).editRoom(id, name, type, file, doorHsycoId, temperatureComponent, externalId, customSettings, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit user preset
     * @param {string} id
     * @param {PresetCreateEditInput} [presetCreateEditInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    editUserPreset(id, presetCreateEditInput, options) {
        return exports.RoomApiFp(this.configuration).editUserPreset(id, presetCreateEditInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Generate check in code
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    generateCheckInCode(id, options) {
        return exports.RoomApiFp(this.configuration).generateCheckInCode(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin room air conditioning
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    getAdminRoomAirConditioning(id, options) {
        return exports.RoomApiFp(this.configuration).getAdminRoomAirConditioning(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get air conditioning schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    getAirConditioningSchedule(options) {
        return exports.RoomApiFp(this.configuration).getAirConditioningSchedule(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get room air conditioning
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    getRoomAirConditioning(options) {
        return exports.RoomApiFp(this.configuration).getRoomAirConditioning(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get room by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    getRoomById(id, options) {
        return exports.RoomApiFp(this.configuration).getRoomById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get room door status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    getRoomDoorStatus(options) {
        return exports.RoomApiFp(this.configuration).getRoomDoorStatus(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get temperatures
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    getTemperatures(options) {
        return exports.RoomApiFp(this.configuration).getTemperatures(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get user preset by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    getUserPresetById(id, options) {
        return exports.RoomApiFp(this.configuration).getUserPresetById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get user room presets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    getUserRoomPresets(options) {
        return exports.RoomApiFp(this.configuration).getUserRoomPresets(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Open room door
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    openRoomDoor(options) {
        return exports.RoomApiFp(this.configuration).openRoomDoor(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Schedule room air conditioning
     * @param {EditOrCreateDomoticItemSchedule} [editOrCreateDomoticItemSchedule]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    scheduleRoomAirConditioning(editOrCreateDomoticItemSchedule, options) {
        return exports.RoomApiFp(this.configuration).scheduleRoomAirConditioning(editOrCreateDomoticItemSchedule, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Set room air conditioning
     * @param {EditAirConditioningInput} [editAirConditioningInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    setRoomAirConditioning(editAirConditioningInput, options) {
        return exports.RoomApiFp(this.configuration).setRoomAirConditioning(editAirConditioningInput, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.RoomApi = RoomApi;
