"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuildingConditioningModeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var BuildingConditioningModeEnum;
(function (BuildingConditioningModeEnum) {
    BuildingConditioningModeEnum["Heating"] = "heating";
    BuildingConditioningModeEnum["Cooling"] = "cooling";
    BuildingConditioningModeEnum["Dehumidifier"] = "dehumidifier";
    BuildingConditioningModeEnum["NoConditioning"] = "noConditioning";
})(BuildingConditioningModeEnum = exports.BuildingConditioningModeEnum || (exports.BuildingConditioningModeEnum = {}));
