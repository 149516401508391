"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HelpApi = exports.HelpApiFactory = exports.HelpApiFp = exports.HelpApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * HelpApi - axios parameter creator
 * @export
 */
exports.HelpApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Add tutorial to shown help videos
         * @param {string} building
         * @param {string} tutorial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTutorialToShownHelpVideos: (building, tutorial, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'building' is not null or undefined
            common_1.assertParamExists('addTutorialToShownHelpVideos', 'building', building);
            // verify required parameter 'tutorial' is not null or undefined
            common_1.assertParamExists('addTutorialToShownHelpVideos', 'tutorial', tutorial);
            const localVarPath = `/admin/help/{building}/showTutorial/{tutorial}`
                .replace(`{${"building"}}`, encodeURIComponent(String(building)))
                .replace(`{${"tutorial"}}`, encodeURIComponent(String(tutorial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit help
         * @param {string} building
         * @param {EditCreateHelpInput} [editCreateHelpInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editHelp: (building, editCreateHelpInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'building' is not null or undefined
            common_1.assertParamExists('editHelp', 'building', building);
            const localVarPath = `/admin/help/{building}/`
                .replace(`{${"building"}}`, encodeURIComponent(String(building)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateHelpInput, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin building help
         * @param {string} building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminBuildingHelp: (building, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'building' is not null or undefined
            common_1.assertParamExists('getAdminBuildingHelp', 'building', building);
            const localVarPath = `/admin/help/{building}/`
                .replace(`{${"building"}}`, encodeURIComponent(String(building)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get user building help
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBuildingHelp: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/help/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Remove tutorial from shown help videos
         * @param {string} building
         * @param {string} tutorial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTutorialFromShownHelpVideos: (building, tutorial, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'building' is not null or undefined
            common_1.assertParamExists('removeTutorialFromShownHelpVideos', 'building', building);
            // verify required parameter 'tutorial' is not null or undefined
            common_1.assertParamExists('removeTutorialFromShownHelpVideos', 'tutorial', tutorial);
            const localVarPath = `/admin/help/{building}/hideTutorial/{tutorial}`
                .replace(`{${"building"}}`, encodeURIComponent(String(building)))
                .replace(`{${"tutorial"}}`, encodeURIComponent(String(tutorial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * HelpApi - functional programming interface
 * @export
 */
exports.HelpApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.HelpApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Add tutorial to shown help videos
         * @param {string} building
         * @param {string} tutorial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTutorialToShownHelpVideos(building, tutorial, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addTutorialToShownHelpVideos(building, tutorial, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit help
         * @param {string} building
         * @param {EditCreateHelpInput} [editCreateHelpInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editHelp(building, editCreateHelpInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editHelp(building, editCreateHelpInput, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin building help
         * @param {string} building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminBuildingHelp(building, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminBuildingHelp(building, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get user building help
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBuildingHelp(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUserBuildingHelp(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Remove tutorial from shown help videos
         * @param {string} building
         * @param {string} tutorial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTutorialFromShownHelpVideos(building, tutorial, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeTutorialFromShownHelpVideos(building, tutorial, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * HelpApi - factory interface
 * @export
 */
exports.HelpApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.HelpApiFp(configuration);
    return {
        /**
         *
         * @summary Add tutorial to shown help videos
         * @param {string} building
         * @param {string} tutorial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTutorialToShownHelpVideos(building, tutorial, options) {
            return localVarFp.addTutorialToShownHelpVideos(building, tutorial, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit help
         * @param {string} building
         * @param {EditCreateHelpInput} [editCreateHelpInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editHelp(building, editCreateHelpInput, options) {
            return localVarFp.editHelp(building, editCreateHelpInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin building help
         * @param {string} building
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminBuildingHelp(building, options) {
            return localVarFp.getAdminBuildingHelp(building, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get user building help
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBuildingHelp(options) {
            return localVarFp.getUserBuildingHelp(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Remove tutorial from shown help videos
         * @param {string} building
         * @param {string} tutorial
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTutorialFromShownHelpVideos(building, tutorial, options) {
            return localVarFp.removeTutorialFromShownHelpVideos(building, tutorial, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * HelpApi - object-oriented interface
 * @export
 * @class HelpApi
 * @extends {BaseAPI}
 */
class HelpApi extends base_1.BaseAPI {
    /**
     *
     * @summary Add tutorial to shown help videos
     * @param {string} building
     * @param {string} tutorial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpApi
     */
    addTutorialToShownHelpVideos(building, tutorial, options) {
        return exports.HelpApiFp(this.configuration).addTutorialToShownHelpVideos(building, tutorial, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit help
     * @param {string} building
     * @param {EditCreateHelpInput} [editCreateHelpInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpApi
     */
    editHelp(building, editCreateHelpInput, options) {
        return exports.HelpApiFp(this.configuration).editHelp(building, editCreateHelpInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin building help
     * @param {string} building
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpApi
     */
    getAdminBuildingHelp(building, options) {
        return exports.HelpApiFp(this.configuration).getAdminBuildingHelp(building, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get user building help
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpApi
     */
    getUserBuildingHelp(options) {
        return exports.HelpApiFp(this.configuration).getUserBuildingHelp(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Remove tutorial from shown help videos
     * @param {string} building
     * @param {string} tutorial
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpApi
     */
    removeTutorialFromShownHelpVideos(building, tutorial, options) {
        return exports.HelpApiFp(this.configuration).removeTutorialFromShownHelpVideos(building, tutorial, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.HelpApi = HelpApi;
