"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryApi = exports.CategoryApiFactory = exports.CategoryApiFp = exports.CategoryApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * CategoryApi - axios parameter creator
 * @export
 */
exports.CategoryApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Gets all categories
         * @summary All categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCategories: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/category/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create category
         * @param {InputCreateEditCategory} inputCreateEditCategory Create a category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory: (inputCreateEditCategory, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'inputCreateEditCategory' is not null or undefined
            common_1.assertParamExists('createCategory', 'inputCreateEditCategory', inputCreateEditCategory);
            const localVarPath = `/category/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputCreateEditCategory, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * delete a category
         * @summary Delete category
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deleteCategory', 'id', id);
            const localVarPath = `/category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Edit a category
         * @summary Edit category
         * @param {string} id
         * @param {InputCreateEditCategory} inputCreateEditCategory Edit a category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCategory: (id, inputCreateEditCategory, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editCategory', 'id', id);
            // verify required parameter 'inputCreateEditCategory' is not null or undefined
            common_1.assertParamExists('editCategory', 'inputCreateEditCategory', inputCreateEditCategory);
            const localVarPath = `/category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputCreateEditCategory, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get category by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getCategoryById', 'id', id);
            const localVarPath = `/category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * CategoryApi - functional programming interface
 * @export
 */
exports.CategoryApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.CategoryApiAxiosParamCreator(configuration);
    return {
        /**
         * Gets all categories
         * @summary All categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCategories(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.allCategories(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Create category
         * @param {InputCreateEditCategory} inputCreateEditCategory Create a category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory(inputCreateEditCategory, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createCategory(inputCreateEditCategory, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * delete a category
         * @summary Delete category
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteCategory(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Edit a category
         * @summary Edit category
         * @param {string} id
         * @param {InputCreateEditCategory} inputCreateEditCategory Edit a category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCategory(id, inputCreateEditCategory, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editCategory(id, inputCreateEditCategory, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get category by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCategoryById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * CategoryApi - factory interface
 * @export
 */
exports.CategoryApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.CategoryApiFp(configuration);
    return {
        /**
         * Gets all categories
         * @summary All categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allCategories(options) {
            return localVarFp.allCategories(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create category
         * @param {InputCreateEditCategory} inputCreateEditCategory Create a category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory(inputCreateEditCategory, options) {
            return localVarFp.createCategory(inputCreateEditCategory, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a category
         * @summary Delete category
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(id, options) {
            return localVarFp.deleteCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit a category
         * @summary Edit category
         * @param {string} id
         * @param {InputCreateEditCategory} inputCreateEditCategory Edit a category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCategory(id, inputCreateEditCategory, options) {
            return localVarFp.editCategory(id, inputCreateEditCategory, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get category by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryById(id, options) {
            return localVarFp.getCategoryById(id, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
class CategoryApi extends base_1.BaseAPI {
    /**
     * Gets all categories
     * @summary All categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    allCategories(options) {
        return exports.CategoryApiFp(this.configuration).allCategories(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create category
     * @param {InputCreateEditCategory} inputCreateEditCategory Create a category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    createCategory(inputCreateEditCategory, options) {
        return exports.CategoryApiFp(this.configuration).createCategory(inputCreateEditCategory, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * delete a category
     * @summary Delete category
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    deleteCategory(id, options) {
        return exports.CategoryApiFp(this.configuration).deleteCategory(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Edit a category
     * @summary Edit category
     * @param {string} id
     * @param {InputCreateEditCategory} inputCreateEditCategory Edit a category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    editCategory(id, inputCreateEditCategory, options) {
        return exports.CategoryApiFp(this.configuration).editCategory(id, inputCreateEditCategory, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get category by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    getCategoryById(id, options) {
        return exports.CategoryApiFp(this.configuration).getCategoryById(id, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.CategoryApi = CategoryApi;
