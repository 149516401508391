"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountApi = exports.AccountApiFactory = exports.AccountApiFp = exports.AccountApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AccountApi - axios parameter creator
 * @export
 */
exports.AccountApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Add user document
         * @param {string} id
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserDocument: (id, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('addUserDocument', 'id', id);
            const localVarPath = `/admin/account/{id}/document/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Admin edit account
         * @param {string} id
         * @param {InputAdminEditAccount} [inputAdminEditAccount]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditAccount: (id, inputAdminEditAccount, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('adminEditAccount', 'id', id);
            const localVarPath = `/admin/account/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputAdminEditAccount, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete fcm token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFcmToken: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/account/fcm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete user document
         * @param {string} id
         * @param {string} documentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserDocument: (id, documentId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deleteUserDocument', 'id', id);
            // verify required parameter 'documentId' is not null or undefined
            common_1.assertParamExists('deleteUserDocument', 'documentId', documentId);
            const localVarPath = `/admin/account/{id}/document/{documentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit preferences
         * @param {EditCreatePreferencesInput} editCreatePreferencesInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPreferences: (editCreatePreferencesInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'editCreatePreferencesInput' is not null or undefined
            common_1.assertParamExists('editPreferences', 'editCreatePreferencesInput', editCreatePreferencesInput);
            const localVarPath = `/account/preferences/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreatePreferencesInput, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit privacy settings
         * @param {PrivacySettingsEditModel} [privacySettingsEditModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPrivacySettings: (privacySettingsEditModel, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/account/preferences/privacy/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(privacySettingsEditModel, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit user
         * @param {string} [description]
         * @param {string} [interests]
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUser: (description, interests, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/account/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (description !== undefined) {
                localVarFormParams.append('description', description);
            }
            if (interests !== undefined) {
                localVarFormParams.append('interests', interests);
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin user by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getAdminUserById', 'id', id);
            const localVarPath = `/admin/account/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin user documents
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserDocuments: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getAdminUserDocuments', 'id', id);
            const localVarPath = `/admin/account/{id}/document/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/account/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get my activities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyActivities: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/account/activities/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get my preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPreferences: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/account/preferences/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get user by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getUserById', 'id', id);
            const localVarPath = `/account/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get user documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDocuments: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/account/documents/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get user room
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRoom: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getUserRoom', 'id', id);
            const localVarPath = `/account/{id}/room/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Recover user
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverUser: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('recoverUser', 'id', id);
            const localVarPath = `/admin/account/{id}/recover`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Set password
         * @param {InputSetPassword} [inputSetPassword]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPassword: (inputSetPassword, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/account/setPassword/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputSetPassword, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update fcm token
         * @param {InputUpdateFcmToken} [inputUpdateFcmToken]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFcmToken: (inputUpdateFcmToken, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/account/fcm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputUpdateFcmToken, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AccountApi - functional programming interface
 * @export
 */
exports.AccountApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AccountApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Add user document
         * @param {string} id
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserDocument(id, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addUserDocument(id, file, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Admin edit account
         * @param {string} id
         * @param {InputAdminEditAccount} [inputAdminEditAccount]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditAccount(id, inputAdminEditAccount, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.adminEditAccount(id, inputAdminEditAccount, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete fcm token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFcmToken(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteFcmToken(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete user document
         * @param {string} id
         * @param {string} documentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserDocument(id, documentId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteUserDocument(id, documentId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit preferences
         * @param {EditCreatePreferencesInput} editCreatePreferencesInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPreferences(editCreatePreferencesInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editPreferences(editCreatePreferencesInput, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit privacy settings
         * @param {PrivacySettingsEditModel} [privacySettingsEditModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPrivacySettings(privacySettingsEditModel, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editPrivacySettings(privacySettingsEditModel, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit user
         * @param {string} [description]
         * @param {string} [interests]
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUser(description, interests, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editUser(description, interests, file, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin user by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminUserById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin user documents
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserDocuments(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminUserDocuments(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getMe(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get my activities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyActivities(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getMyActivities(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get my preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPreferences(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getMyPreferences(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get user by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUserById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get user documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDocuments(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUserDocuments(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get user room
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRoom(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUserRoom(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Recover user
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverUser(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.recoverUser(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Set password
         * @param {InputSetPassword} [inputSetPassword]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPassword(inputSetPassword, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setPassword(inputSetPassword, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Update fcm token
         * @param {InputUpdateFcmToken} [inputUpdateFcmToken]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFcmToken(inputUpdateFcmToken, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateFcmToken(inputUpdateFcmToken, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AccountApi - factory interface
 * @export
 */
exports.AccountApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AccountApiFp(configuration);
    return {
        /**
         *
         * @summary Add user document
         * @param {string} id
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserDocument(id, file, options) {
            return localVarFp.addUserDocument(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Admin edit account
         * @param {string} id
         * @param {InputAdminEditAccount} [inputAdminEditAccount]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEditAccount(id, inputAdminEditAccount, options) {
            return localVarFp.adminEditAccount(id, inputAdminEditAccount, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete fcm token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFcmToken(options) {
            return localVarFp.deleteFcmToken(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete user document
         * @param {string} id
         * @param {string} documentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserDocument(id, documentId, options) {
            return localVarFp.deleteUserDocument(id, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit preferences
         * @param {EditCreatePreferencesInput} editCreatePreferencesInput
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPreferences(editCreatePreferencesInput, options) {
            return localVarFp.editPreferences(editCreatePreferencesInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit privacy settings
         * @param {PrivacySettingsEditModel} [privacySettingsEditModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPrivacySettings(privacySettingsEditModel, options) {
            return localVarFp.editPrivacySettings(privacySettingsEditModel, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit user
         * @param {string} [description]
         * @param {string} [interests]
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUser(description, interests, file, options) {
            return localVarFp.editUser(description, interests, file, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin user by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserById(id, options) {
            return localVarFp.getAdminUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin user documents
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserDocuments(id, options) {
            return localVarFp.getAdminUserDocuments(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options) {
            return localVarFp.getMe(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get my activities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyActivities(options) {
            return localVarFp.getMyActivities(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get my preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPreferences(options) {
            return localVarFp.getMyPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get user by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(id, options) {
            return localVarFp.getUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get user documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDocuments(options) {
            return localVarFp.getUserDocuments(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get user room
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRoom(id, options) {
            return localVarFp.getUserRoom(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Recover user
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverUser(id, options) {
            return localVarFp.recoverUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Set password
         * @param {InputSetPassword} [inputSetPassword]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPassword(inputSetPassword, options) {
            return localVarFp.setPassword(inputSetPassword, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update fcm token
         * @param {InputUpdateFcmToken} [inputUpdateFcmToken]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFcmToken(inputUpdateFcmToken, options) {
            return localVarFp.updateFcmToken(inputUpdateFcmToken, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
class AccountApi extends base_1.BaseAPI {
    /**
     *
     * @summary Add user document
     * @param {string} id
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    addUserDocument(id, file, options) {
        return exports.AccountApiFp(this.configuration).addUserDocument(id, file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Admin edit account
     * @param {string} id
     * @param {InputAdminEditAccount} [inputAdminEditAccount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    adminEditAccount(id, inputAdminEditAccount, options) {
        return exports.AccountApiFp(this.configuration).adminEditAccount(id, inputAdminEditAccount, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete fcm token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    deleteFcmToken(options) {
        return exports.AccountApiFp(this.configuration).deleteFcmToken(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete user document
     * @param {string} id
     * @param {string} documentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    deleteUserDocument(id, documentId, options) {
        return exports.AccountApiFp(this.configuration).deleteUserDocument(id, documentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit preferences
     * @param {EditCreatePreferencesInput} editCreatePreferencesInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    editPreferences(editCreatePreferencesInput, options) {
        return exports.AccountApiFp(this.configuration).editPreferences(editCreatePreferencesInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit privacy settings
     * @param {PrivacySettingsEditModel} [privacySettingsEditModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    editPrivacySettings(privacySettingsEditModel, options) {
        return exports.AccountApiFp(this.configuration).editPrivacySettings(privacySettingsEditModel, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit user
     * @param {string} [description]
     * @param {string} [interests]
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    editUser(description, interests, file, options) {
        return exports.AccountApiFp(this.configuration).editUser(description, interests, file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin user by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    getAdminUserById(id, options) {
        return exports.AccountApiFp(this.configuration).getAdminUserById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin user documents
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    getAdminUserDocuments(id, options) {
        return exports.AccountApiFp(this.configuration).getAdminUserDocuments(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    getMe(options) {
        return exports.AccountApiFp(this.configuration).getMe(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get my activities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    getMyActivities(options) {
        return exports.AccountApiFp(this.configuration).getMyActivities(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get my preferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    getMyPreferences(options) {
        return exports.AccountApiFp(this.configuration).getMyPreferences(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get user by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    getUserById(id, options) {
        return exports.AccountApiFp(this.configuration).getUserById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get user documents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    getUserDocuments(options) {
        return exports.AccountApiFp(this.configuration).getUserDocuments(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get user room
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    getUserRoom(id, options) {
        return exports.AccountApiFp(this.configuration).getUserRoom(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Recover user
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    recoverUser(id, options) {
        return exports.AccountApiFp(this.configuration).recoverUser(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Set password
     * @param {InputSetPassword} [inputSetPassword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    setPassword(inputSetPassword, options) {
        return exports.AccountApiFp(this.configuration).setPassword(inputSetPassword, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update fcm token
     * @param {InputUpdateFcmToken} [inputUpdateFcmToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    updateFcmToken(inputUpdateFcmToken, options) {
        return exports.AccountApiFp(this.configuration).updateFcmToken(inputUpdateFcmToken, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AccountApi = AccountApi;
