"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TutorialApi = exports.TutorialApiFactory = exports.TutorialApiFp = exports.TutorialApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * TutorialApi - axios parameter creator
 * @export
 */
exports.TutorialApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *  **Permission required**: [\"create\", \"Tutorial\"]
         * @summary Create tutorial | Permission required: [\"create\", \"Tutorial\"]
         * @param {string} name
         * @param {string} [description]
         * @param {Array<any>} [files]
         * @param {number} [duration]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTutorial: (name, description, files, duration, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'name' is not null or undefined
            common_1.assertParamExists('createTutorial', 'name', name);
            const localVarPath = `/admin/tutorial/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (name !== undefined) {
                localVarFormParams.append('name', name);
            }
            if (description !== undefined) {
                localVarFormParams.append('description', description);
            }
            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element);
                });
            }
            if (duration !== undefined) {
                localVarFormParams.append('duration', duration);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete tutorial
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTutorial: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deleteTutorial', 'id', id);
            const localVarPath = `/admin/tutorial/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit tutorial
         * @param {string} id
         * @param {string} name
         * @param {string} [description]
         * @param {Array<any>} [files]
         * @param {number} [duration]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTutorial: (id, name, description, files, duration, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editTutorial', 'id', id);
            // verify required parameter 'name' is not null or undefined
            common_1.assertParamExists('editTutorial', 'name', name);
            const localVarPath = `/admin/tutorial/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (name !== undefined) {
                localVarFormParams.append('name', name);
            }
            if (description !== undefined) {
                localVarFormParams.append('description', description);
            }
            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element);
                });
            }
            if (duration !== undefined) {
                localVarFormParams.append('duration', duration);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin building tutorials
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminBuildingTutorials: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getAdminBuildingTutorials', 'id', id);
            const localVarPath = `/admin/tutorial/building/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin tutorial by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTutorialById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getAdminTutorialById', 'id', id);
            const localVarPath = `/admin/tutorial/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all admin tutorials
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminTutorials: (filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/tutorial/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all user tutorials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserTutorials: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/tutorial/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get tutorial by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutorialById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getTutorialById', 'id', id);
            const localVarPath = `/tutorial/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get tutorial url
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutorialUrl: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getTutorialUrl', 'id', id);
            const localVarPath = `/admin/tutorial/{id}/generateUrl/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * TutorialApi - functional programming interface
 * @export
 */
exports.TutorialApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.TutorialApiAxiosParamCreator(configuration);
    return {
        /**
         *  **Permission required**: [\"create\", \"Tutorial\"]
         * @summary Create tutorial | Permission required: [\"create\", \"Tutorial\"]
         * @param {string} name
         * @param {string} [description]
         * @param {Array<any>} [files]
         * @param {number} [duration]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTutorial(name, description, files, duration, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createTutorial(name, description, files, duration, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete tutorial
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTutorial(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteTutorial(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit tutorial
         * @param {string} id
         * @param {string} name
         * @param {string} [description]
         * @param {Array<any>} [files]
         * @param {number} [duration]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTutorial(id, name, description, files, duration, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editTutorial(id, name, description, files, duration, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin building tutorials
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminBuildingTutorials(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminBuildingTutorials(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin tutorial by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTutorialById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminTutorialById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all admin tutorials
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminTutorials(filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllAdminTutorials(filter, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all user tutorials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserTutorials(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllUserTutorials(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get tutorial by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutorialById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTutorialById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get tutorial url
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutorialUrl(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTutorialUrl(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * TutorialApi - factory interface
 * @export
 */
exports.TutorialApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.TutorialApiFp(configuration);
    return {
        /**
         *  **Permission required**: [\"create\", \"Tutorial\"]
         * @summary Create tutorial | Permission required: [\"create\", \"Tutorial\"]
         * @param {string} name
         * @param {string} [description]
         * @param {Array<any>} [files]
         * @param {number} [duration]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTutorial(name, description, files, duration, options) {
            return localVarFp.createTutorial(name, description, files, duration, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete tutorial
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTutorial(id, options) {
            return localVarFp.deleteTutorial(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit tutorial
         * @param {string} id
         * @param {string} name
         * @param {string} [description]
         * @param {Array<any>} [files]
         * @param {number} [duration]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTutorial(id, name, description, files, duration, options) {
            return localVarFp.editTutorial(id, name, description, files, duration, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin building tutorials
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminBuildingTutorials(id, options) {
            return localVarFp.getAdminBuildingTutorials(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin tutorial by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminTutorialById(id, options) {
            return localVarFp.getAdminTutorialById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all admin tutorials
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminTutorials(filter, options) {
            return localVarFp.getAllAdminTutorials(filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all user tutorials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserTutorials(options) {
            return localVarFp.getAllUserTutorials(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get tutorial by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutorialById(id, options) {
            return localVarFp.getTutorialById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get tutorial url
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTutorialUrl(id, options) {
            return localVarFp.getTutorialUrl(id, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * TutorialApi - object-oriented interface
 * @export
 * @class TutorialApi
 * @extends {BaseAPI}
 */
class TutorialApi extends base_1.BaseAPI {
    /**
     *  **Permission required**: [\"create\", \"Tutorial\"]
     * @summary Create tutorial | Permission required: [\"create\", \"Tutorial\"]
     * @param {string} name
     * @param {string} [description]
     * @param {Array<any>} [files]
     * @param {number} [duration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutorialApi
     */
    createTutorial(name, description, files, duration, options) {
        return exports.TutorialApiFp(this.configuration).createTutorial(name, description, files, duration, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete tutorial
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutorialApi
     */
    deleteTutorial(id, options) {
        return exports.TutorialApiFp(this.configuration).deleteTutorial(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit tutorial
     * @param {string} id
     * @param {string} name
     * @param {string} [description]
     * @param {Array<any>} [files]
     * @param {number} [duration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutorialApi
     */
    editTutorial(id, name, description, files, duration, options) {
        return exports.TutorialApiFp(this.configuration).editTutorial(id, name, description, files, duration, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin building tutorials
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutorialApi
     */
    getAdminBuildingTutorials(id, options) {
        return exports.TutorialApiFp(this.configuration).getAdminBuildingTutorials(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin tutorial by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutorialApi
     */
    getAdminTutorialById(id, options) {
        return exports.TutorialApiFp(this.configuration).getAdminTutorialById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all admin tutorials
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutorialApi
     */
    getAllAdminTutorials(filter, options) {
        return exports.TutorialApiFp(this.configuration).getAllAdminTutorials(filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all user tutorials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutorialApi
     */
    getAllUserTutorials(options) {
        return exports.TutorialApiFp(this.configuration).getAllUserTutorials(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get tutorial by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutorialApi
     */
    getTutorialById(id, options) {
        return exports.TutorialApiFp(this.configuration).getTutorialById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get tutorial url
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutorialApi
     */
    getTutorialUrl(id, options) {
        return exports.TutorialApiFp(this.configuration).getTutorialUrl(id, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.TutorialApi = TutorialApi;
