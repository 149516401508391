"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * belvillage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationApi = exports.NotificationApiFactory = exports.NotificationApiFp = exports.NotificationApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * NotificationApi - axios parameter creator
 * @export
 */
exports.NotificationApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create building notification
         * @param {string} id
         * @param {EditCreateNotificationSchedule} [editCreateNotificationSchedule]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuildingNotification: (id, editCreateNotificationSchedule, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('createBuildingNotification', 'id', id);
            const localVarPath = `/admin/notification/building/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateNotificationSchedule, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create general notification
         * @param {EditCreateNotificationSchedule} [editCreateNotificationSchedule]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeneralNotification: (editCreateNotificationSchedule, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/notification/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateNotificationSchedule, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete admin notification
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminNotification: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deleteAdminNotification', 'id', id);
            const localVarPath = `/admin/notification/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit admin notification
         * @param {string} id
         * @param {EditCreateNotificationSchedule} [editCreateNotificationSchedule]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdminNotification: (id, editCreateNotificationSchedule, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editAdminNotification', 'id', id);
            const localVarPath = `/admin/notification/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(editCreateNotificationSchedule, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get admin notification by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminNotificationById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getAdminNotificationById', 'id', id);
            const localVarPath = `/admin/notification/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all admin notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminNotifications: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/notification/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all user notifications
         * @param {number} [page]
         * @param {number} [perPage]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserNotifications: (page, perPage, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/notification/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get building admin notifications
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuildingAdminNotifications: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getBuildingAdminNotifications', 'id', id);
            const localVarPath = `/admin/notification/building/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Read all notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllNotifications: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/notification/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Read notification
         * @param {string} notificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNotification: (notificationId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'notificationId' is not null or undefined
            common_1.assertParamExists('readNotification', 'notificationId', notificationId);
            const localVarPath = `/notification/{notificationId}/`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "authorization", configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * NotificationApi - functional programming interface
 * @export
 */
exports.NotificationApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.NotificationApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Create building notification
         * @param {string} id
         * @param {EditCreateNotificationSchedule} [editCreateNotificationSchedule]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuildingNotification(id, editCreateNotificationSchedule, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createBuildingNotification(id, editCreateNotificationSchedule, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Create general notification
         * @param {EditCreateNotificationSchedule} [editCreateNotificationSchedule]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeneralNotification(editCreateNotificationSchedule, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createGeneralNotification(editCreateNotificationSchedule, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete admin notification
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminNotification(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteAdminNotification(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit admin notification
         * @param {string} id
         * @param {EditCreateNotificationSchedule} [editCreateNotificationSchedule]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdminNotification(id, editCreateNotificationSchedule, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editAdminNotification(id, editCreateNotificationSchedule, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get admin notification by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminNotificationById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAdminNotificationById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all admin notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminNotifications(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllAdminNotifications(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all user notifications
         * @param {number} [page]
         * @param {number} [perPage]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserNotifications(page, perPage, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllUserNotifications(page, perPage, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get building admin notifications
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuildingAdminNotifications(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getBuildingAdminNotifications(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Read all notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllNotifications(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readAllNotifications(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Read notification
         * @param {string} notificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNotification(notificationId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readNotification(notificationId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * NotificationApi - factory interface
 * @export
 */
exports.NotificationApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.NotificationApiFp(configuration);
    return {
        /**
         *
         * @summary Create building notification
         * @param {string} id
         * @param {EditCreateNotificationSchedule} [editCreateNotificationSchedule]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBuildingNotification(id, editCreateNotificationSchedule, options) {
            return localVarFp.createBuildingNotification(id, editCreateNotificationSchedule, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create general notification
         * @param {EditCreateNotificationSchedule} [editCreateNotificationSchedule]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeneralNotification(editCreateNotificationSchedule, options) {
            return localVarFp.createGeneralNotification(editCreateNotificationSchedule, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete admin notification
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminNotification(id, options) {
            return localVarFp.deleteAdminNotification(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit admin notification
         * @param {string} id
         * @param {EditCreateNotificationSchedule} [editCreateNotificationSchedule]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAdminNotification(id, editCreateNotificationSchedule, options) {
            return localVarFp.editAdminNotification(id, editCreateNotificationSchedule, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get admin notification by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminNotificationById(id, options) {
            return localVarFp.getAdminNotificationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all admin notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAdminNotifications(options) {
            return localVarFp.getAllAdminNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all user notifications
         * @param {number} [page]
         * @param {number} [perPage]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserNotifications(page, perPage, options) {
            return localVarFp.getAllUserNotifications(page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get building admin notifications
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuildingAdminNotifications(id, options) {
            return localVarFp.getBuildingAdminNotifications(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Read all notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAllNotifications(options) {
            return localVarFp.readAllNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Read notification
         * @param {string} notificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNotification(notificationId, options) {
            return localVarFp.readNotification(notificationId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
class NotificationApi extends base_1.BaseAPI {
    /**
     *
     * @summary Create building notification
     * @param {string} id
     * @param {EditCreateNotificationSchedule} [editCreateNotificationSchedule]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    createBuildingNotification(id, editCreateNotificationSchedule, options) {
        return exports.NotificationApiFp(this.configuration).createBuildingNotification(id, editCreateNotificationSchedule, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create general notification
     * @param {EditCreateNotificationSchedule} [editCreateNotificationSchedule]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    createGeneralNotification(editCreateNotificationSchedule, options) {
        return exports.NotificationApiFp(this.configuration).createGeneralNotification(editCreateNotificationSchedule, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete admin notification
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    deleteAdminNotification(id, options) {
        return exports.NotificationApiFp(this.configuration).deleteAdminNotification(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit admin notification
     * @param {string} id
     * @param {EditCreateNotificationSchedule} [editCreateNotificationSchedule]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    editAdminNotification(id, editCreateNotificationSchedule, options) {
        return exports.NotificationApiFp(this.configuration).editAdminNotification(id, editCreateNotificationSchedule, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get admin notification by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    getAdminNotificationById(id, options) {
        return exports.NotificationApiFp(this.configuration).getAdminNotificationById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all admin notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    getAllAdminNotifications(options) {
        return exports.NotificationApiFp(this.configuration).getAllAdminNotifications(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all user notifications
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    getAllUserNotifications(page, perPage, options) {
        return exports.NotificationApiFp(this.configuration).getAllUserNotifications(page, perPage, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get building admin notifications
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    getBuildingAdminNotifications(id, options) {
        return exports.NotificationApiFp(this.configuration).getBuildingAdminNotifications(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Read all notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    readAllNotifications(options) {
        return exports.NotificationApiFp(this.configuration).readAllNotifications(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Read notification
     * @param {string} notificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    readNotification(notificationId, options) {
        return exports.NotificationApiFp(this.configuration).readNotification(notificationId, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.NotificationApi = NotificationApi;
